import React from "react";

export default function QuestionsAr() {
  return (
    <>
      <h1
        className="text-center"
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        سياسة الأسئلة والإجابات
      </h1>
      <div className="container mt-4">
        <div className="mb-4">
          <h4 className="text-orange">شروط السؤال:</h4>
          <p>
            أن يكون السؤال علمي هندسي فقط ( لن يتم الرد في حال كان السؤال خارج
            نطاق الهندسة )
          </p>
          <p>أن يكون سؤال وليس نص</p>
          <p>سؤال واحد في وقت واحد.</p>
          <p>عدم سرد التفاصيل الشخصية والابتعاد عن المصطلحات الشخصية .</p>
          <p>
            لا نسمح بالمشاركات التي تحتوي على كلمات غير ملائمة أو على كلمات تدل
            على توجهات أخرى مهما كانت ولا علاقة لها بالهندسة .
          </p>
          <p>
            لتفادي الأسئلة المكررة، يجب البحث عن الموضوع أولا على الموقع قبل طرح
            السؤال.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">سياسة الإجابة:</h4>
          <p>
            أن تكون مكتوبة بلغة عربية فصحى تكون سهلة وواضحة للقارئ العربي ويمكنك
            استخدام اللغة الإنجليزية في الإجابة.
          </p>
          <p>أن تكون الإجابة شاملة ومفسرة لكل نقطة بالسؤال.</p>
          <p>
            أن تكون الإجابة مبنية على على أسس علمية هندسية فقط، ولا تعبر عن
            الرأي الشخصي أو مقتبسة من أحد المنتديات .
          </p>
          <p>الابتعاد عن جميع أشكال الدعاية أو الترويج لشركة معينة.</p>
          <p>الابتعاد عن جميع النصوص التي ليس لها علاقة في المجال الهندسي.</p>
          <p>
            الابتعاد عن أي شكل من أشكال الدعاية الشخصية خلال الإجابة,حيث تعتبر
            إجابة المهندس هي جزء من الدعاية الغير مباشرة له , و يمكنه و ضع
            معلومات الاتصال به في ملفه الشخصي , و أن لا يكون ذلك خلال الجواب أو
            أن يدعو السائل للاتصال به أو زيارته أو غير ذلك.
          </p>
          <p>
            الابتعاد عن ذكر اسم الشخص السائل أو أي معلومات خاصة به، موقع
            EyeEngineer يحترم خصوصية الأشخاص.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">احترام رأي المهندس الأخر من خلال:</h4>
          <p>
            الإجابة على السؤال المطروح ومحاولة ابداء وجهة نظره بناء على اسس
            علمية هندسية والابتعاد عن أي توجهات أخرى شخصية أو لا تمت للموضوع بأي
            صلة .
          </p>
          <p style={{color:'#ED7200'}}>
            {" "}
            ملاحظة: في حال تم مخالفة أي من هذه النقاط سيتم حذف الإجابة فورا دون
            إبلاغ المهندس مسبقا.
          </p>
        </div>
      </div>
    </>
  );
}
