import React from "react";

export default function ArticlesAr() {

  return (
    <>
      <h1
        className="text-center"
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        سياسة المقالات
      </h1>
      <div className="container mt-4">
        <div className="mb-4">
          <h4 className="text-orange">نظرة عامة:</h4>
          <p>
            مقالة EyeEngineer عبارة عن مقالة حول موضوع هندسي معين. والمطلوب فيه
            التقيد بالنقاط التالية:
          </p>
          <p>
           - أن تكون المقالة عن المواضيع الهندسية فقط وحصرياً (وبالتالي لا يُسمح
            بأي مقالات أخرى لا علاقة لها بالمواضيع الهندسية المفيدة) .
          </p>
          <p>
           - التقيد بأسلوب المقالة بشكل رسمي والابتعاد عن أسلوب المحادثات غير
            الرسمي.
          </p>
          <p>-أن تكون بسيطة ومفهومة مع الحرص على شرح المصطلحات الهندسية.</p>
          <p>
           - أن تكون المقالة موجهة للفائدة العلمية القائمة على حقائق هندسة علمية
            موثوقة.
          </p>
          <p>
            -توفير أسماء المراجع المستخدمة، وتاريخ نشرها واسم دار النشر والصفحة،
            وكذلك ذكر أسماء المواقع الالكترونية المقتبس منها وتاريخ الاقتباس
            (وهي على مسؤولية كاتب المقال)، وفي حال عدم ذكر ما سبق فان كاتب
            المقال هو المسؤول عن صحة تلك المعلومات علمياً وقانونياً.
          </p>
          <p>-أن تكون المقالة ذات تنسيق جيد من حيث الخط وسلامة الجمل نحوياً.</p>
          <p>-اختيار عنوان مناسب لمحتوى المقالة.</p>
          <p>
           - عدم اشتمال أي مقالة على رسائل بريد غير مرغوب فيها أو برامج ضارة.
          </p>
          <p>
           - التأكد من اتباع جميع قوانين حقوق النشر والقوانين الأخرى المعمول بها.
          </p>
          <p>
          -  مراعاة حقوق النشر، حيث تقوم سياستنا على الاستجابة للإخطارات الصريحة
            التي تدعي وجود حالات انتهاك لحقوق النشرمما يؤدي الى حذف المقال
            مباشرةً.
          </p>
          <p>
          -  ـ تجنب نشر المعلومات الشخصية التي لا يجوز الاطلاع عليها بشكل عام (كل
            ماتحمله المعلومات الشخصية من معنى).
          </p>
          <p>
           - يرجى من مقدمي المعلومات الهندسية الذين يودون الكتابة على موقع
            EyeEngineer ، إرسال الطلب لفريق EyeEngineer <i>info@eyeengineer.com</i>
          </p>
        </div>
      </div>
    </>
  );
}
