export default function AAr() {
  return (
    <>
      <p>
        EyeEngineer هو منصة متخصصة في تقديم خدمة الاستشارات الهندسية عن بُعد
        وتقديم المعلومة الهندسية الموثوقة لمن يحتاجها.
      </p>
      <p>
        إذا كان لديك الاهتمام والرغبة في مناقشة أي موضوع هندسي، نتمنى أن تتواصل
        معنا. فالعلم والمعرفة ليسا حكر على أحد.
      </p>
      <p>شاركنا المعلومة والفائدة من أجل الجميع.</p>
    </>
  );
}
