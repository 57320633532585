import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PolicyComponent.css";

const ArabicPolicy = () => {
  return (
    <>
      <h1
        className="text-center"
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        سياسة الخصوصية
      </h1>
      <div className="container mt-4">
        <div className="mb-4">
          <h4 className="text-orange">مقدمة:</h4>
          <p>
            يدرك موقع EyeEngineer أهمية حماية خصوصية معلوماتك الشخصية، وقد
            أعددنا سياسة الخصوصية هذه لتزويدك بمعلومات مهمة حول ممارسات الخصوصية
            التي تُطبق على منصة EyeEngineer وأي موقع الكتروني أو خدمة تربط
            بسياسة الخصوصية هذه أو تشير إليها (يشار إليها مجتمعة "الخدمات").
          </p>
          <p>
            يعمل موقع EyeEngineer من خلال مساعدتك في البحث عن معلومات هندسية ذات
            صلة وموثوقة، وربطك بأفضل المهندسين، على تزويدك بمعلومات تشاركها مع
            المهندسين.
          </p>
          <p>
            يعمل موقع EyeEngineer من خلال ربطك بالمهندسين في أقرب وقت ممكن عندما
            تحتاج للمعلومة، (بالطريقة المناسبة للاستشارات الافتراضية)، من جهاز
            الكمبيوتر أو الهاتف المحمول الخاص بك بشكل مباشر.
          </p>
          <p>
            بمواصلة استخدام موقع EyeEngineer ، أنت توافق على سياسة الخصوصية هذه
            وأي تحديثات تجرى عليها. مما يعني أنه في حال إجراء تغييرات على سياسة
            الخصوصية، فأنت توافق على تلك التغييرات إذا واصلت استخدام موقع
            EyeEngineer. عند تغيير هذه السياسة (بخلاف التغييرات التي تجرى لتصحيح
            أخطاء مطبعية أو التغييرات البسيطة التي لا تغير معناها)، سنعمل على
            تحديث تاريخ "آخر تعديل" في الجزء الأعلى من السياسة.
          </p>
          <p>
            يمكن أن نقدم في الجزء الأدنى من السياسة، ملخصاً يصف أنواع التغييرات
            التي أجريت.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">الأحكام والشروط في استخدام الموقع:</h4>
          <p>
            أنت توافق على عدم استخدام أي جهاز أو برنامج أو روتين للتدخل أو
            محاولة التدخل في العمل الصحيح للموقع أو أي معاملة تتم على الموقع، أو
            مع أي استخدام شخص آخر للموقع.
          </p>
          <p>
            لا يجوز لك صياغة العناوين أو التلاعب بالمعرفات من أجل إخفاء أصل أي
            رسالة.
          </p>
          <p>ممنوع انتحال شخصية أي فرد أو كيان آخر.</p>
          <p>
            لا يجوز لك استخدام الموقع أو أي محتوى لأي غرض غير قانوني أو محظور
            بموجب شروط الاستخدام هذه.
          </p>
          <p>
            يجب المحافظة على حسابك وكلمات المرور بشكل متقن لأن أي اختراق لحسابك
            من الممكن أن يؤدي الى مشاكل يواجها مستخدم آخر أو ربما لموقعنا
            وخدماتنا، لذلك أنت مسؤول تماماً عن أمن حسابك في الموقع.
          </p>
          <p>
            يجب أن تكون على علم تام بأن أي رسالة أو عملية تقوم بها من الممكن أن
            تكون مرئية من قبل أشخاص آخرين هدفهم الاستغلال.
          </p>
          <p>
            لا يمكن للموقع ضمان أن أي ملفات أو بيانات أخرى تقوم بتحميلها من
            الموقع ستكون خالية من الفيروسات أو الملوثات أو المميزات الداعمة.
          </p>
          <p>
            أنت تتحمل مسؤولية كاملة عن استخدامك للموقع، فأنت تتحمل مسؤولية أي
            أضرار أو مسؤولية أو إصابات ناجمة عن أي فشل في الأداء أو الخطأ أو
            الإهمال أو الانقطاع أو الحذف أو العيب أو التأخير في التشغيل أو
            الإرسال أو فيروس الكمبيوتر أو فشل خط الاتصال أو السرقة أو التدمير أو
            الوصول غير المصرح به.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">جمع المعلومات:</h4>
          <p>
            عند استخدامك للخدمات، يمكن أن نجمع الأنواع التالية من المعلومات:
          </p>

          <h4 className="h4t">المعلومات التي تقدمها لنا مباشرة:</h4>
          <p>
            بالنسبة لأنشطة معينة، على سبيل المثال، عند قيامك بالتسجيل أو
            الاشتراك في تنبيهاتنا أو التواصل معنا مباشرة، يمكن أن نجمع بعض أو كل
            أنواع المعلومات التالية:
          </p>
          <p>
            <span className="h4t">التسجيل:</span>معلومات الاتصال، مثل اسمك وعنوان بريدك
            الالكتروني وتاريخ ميلادك ورقم هاتفك المحمول؛
          </p>
          <p>
            <span className="h4t">معلومات الدفع</span>: عندما تتوفر في موقع EyeEngineer
            الخدمات المأجورة عندها يلزمنا معلومات الدفع. في حال قيامك بتسديد
            دفعة بواسطة بطاقة ائتمانية أو بطاقة الصرف الآلي المحلية ، سنقوم بجمع
            معلومات معينة مثل رقم بطاقتك الائتمانية أو بطاقة الصرف الآلي المحلية
            وتاريخ انتهائها ورمز أمانها، بالإضافة إلى أن مقدم الخدمات (طرف ثالث
            يساعد أو ينوب عنا في تسهيل خدماتنا) الذي يتولى إدارة عمليات الدفع
            لنا، سيحصل على معلومات بطاقتك الائتمانية؛ أو بطاقة الصرف الآلي
            المحلية, وسيكون استعمالهم لهذه المعلومات معالج بسياسة الخصوصية
            الخاصة بهم .
          </p>

          <h4 className="h4t">مقدمي المعلومات الهندسية:</h4>
          <p>
            سنجمع معلومات معينة حول عملك، مثل المسمى الوظيفي ومجال الممارسة
            والتخصص الأساسي والجنس وتاريخ الميلاد واللغات المحكية والعنوان
            والصورة ومعلومات الرخصة المهنية ومعلومات الحساب البنكي؛
          </p>

          <h4 className="h4t">المعلومات الأخرى: </h4>
          <p>سنجمع أيضاً أي معلومات أخرى تقدمها لنا.</p>
          <h4 className="h4t">المعلومات التي نجمعها تلقائياً:</h4>
          <p>
            يمكن أن نقوم تلقائياً بجمع معلومات معينة عند استخدامك لخدماتنا، مثل
            عنوان بروتوكول الانترنت (IP) ومعرّفات الجهاز والإعلان ونوع المتصفح
            ونظام التشغيل ومزود خدمة الانترنت والصفحات التي زرتها قبل وبعد
            استخدام الخدمات، وتاريخ ووقت الزيارة، ومعلومات عن الروابط التي تنقر
            عليها والصفحات التي تعرضها في الخدمات، وغيرها من معلومات سجل الخادم
            المعيارية.
          </p>
          <p>
            يمكن أن نقوم تلقائياً بجمع معلومات معينة عند استخدامك لخدماتنا، مثل
            عنوان بروتوكول الانترنت (IP) ومعرّفات الجهاز والإعلان ونوع المتصفح
            ونظام التشغيل ومزود خدمة الانترنت والصفحات التي زرتها قبل وبعد
            استخدام الخدمات، وتاريخ ووقت الزيارة، ومعلومات عن الروابط التي تنقر
            عليها والصفحات التي تعرضها في الخدمات، وغيرها من معلومات سجل الخادم
            المعيارية.
          </p>
          <p>
            يمكن أن تستخدم ملفات تعريف الارتباط ووحدات البيكسل وكائنات مشتركة
            محلية وتقنيات مشابهة لجمع هذه المعلومات تلقائياً. ملفات تعريف
            الارتباط هي أجزاء صغيرة من معلومات مخزنة بواسطة متصفح الويب في جهاز
            الكمبيوتر الخاص بك. وحدات البيكسل هي صور صغيرة جداً أو أجزاء صغيرة
            من بيانات مضمنة في الصور، وتعرف أيضاً باسم "إشارات الويب" أو "ملفات
            GIF الواضحة"، والتي يمكنها التعرف على ملفات تعريف الارتباط ووقت
            وتاريخ عرض الصفحة ووصف للصفحة التي تتواجد فيها وحدة البيكسل ومعلومات
            مشابهة من الكمبيوتر أو الجهاز الخاص بك.
          </p>
          <p>
            الكائنات المشتركة المحلية (يشار إليها أحياناً باسم "ملفات تعريف
            الارتباط فلاش") تشبه ملفات تعريف الارتباط المعيارية باستثناء أنها قد
            تكون أكبر حجماً ويتم تنزيلها على جهاز الكمبيوتر أو الهاتف المحمول
            بواسطة مشغل وسائط Adobe Flash. باستخدامك للخدمات، توافق على
            استخدامنا لملفات تعريف الارتباط والكائنات المشتركة المحلية ووحدات
            البيكسل والتقنيات المشابهة.
          </p>
          <p>
            يمكن أيضاً أن نجمع بيانات فنية لمعالجة وإصلاح المشاكل الفنية وتحسين
            خدماتنا، بما في ذلك وضع الذاكرة في جهازك عند حدوث عطل في نظام أو
            تطبيق أثناء استخدامك لخدماتنا. قد تسمح لك إعدادات جهازك أو المتصفح
            بالتحكم في جمع هذه البيانات الفنية. قد تتضمن هذه البيانات أجزاء من
            وثيقة كنت تستخدمها عند حدوث مشكلة، أو محتويات اتصالاتك. باستخدام
            الخدمات، توافق على جمع هذه البيانات الفنية.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="h4t">استخدام المعلومات:</h4>
          <p>بشكل عام نستخدم المعلومات التي نجمعها عبر الانترنت من أجل:</p>
          <p>تشغيل خدماتنا وتوفيرها والمحافظة عليها وتحسينها وتعزيزها؛</p>
          <p>
            إضفاء الطابع الشخصي على تجربتك معنا، على سبيل المثال من خلال تقديم
            محتوى وتوصيات مخصصة. على سبيل المثال نستخدم عنوان بريدك الالكتروني
            لمساعدتك على إنشاء حسابك على خدماتنا وتسجيل الدخول إليه وإدارته. مما
            يتيح لنا إضفاء الطابع الشخصي على تجربتك وتقديم المعلومات ذات الصلة
            لك. كما يدعم الميزات التي تساعدك على فهم التساؤلات المطروحة فيها
            وتتبعها بصورة أفضل وتقديم معلومات مخصصة وذات صلة لك؛
          </p>
          <p>
            إنشاء ملف شخصي عنك واتخاذ قرارات آلية بناء على معلوماتك لوضع إجابات
            أفضل للأسئلة المطروحة لا تُستخدم هذه المعلومات لأغراض التسويق.
          </p>
          <p>ربطك مع المهندس الذي يناسب احتياجاتك.</p>
          <p>
            تحقيق أغراض التسويق والدعاية، مثل تطوير وتوفير مواد ترويجية وإعلانية
            قد تكون ذات صلة أو ذات قيمة أو بخلاف ذلك تهمك. كما يمكن أن نستخدم
            المعلومات التي نعرفها عنك لتساعدنا في الإعلان عن خدماتنا على المواقع
            الالكترونية الخاصة بجهات خارجية. حيث يتطلب القانون المعمول به ذلك،
            لن نرسل إليك رسائل تسويقية إلا بموافقتك.
          </p>
          <p>
            التواصل معك عبر البريد الالكتروني والرسائل النصية وإشعارات الدفع إن
            وجدت والمكالمات الهاتفية، لتزويدك بآخر المستجدات والمعلومات الأخرى
            المتعلقة بخدماتنا وتقديم المعلومات التي تطلبها والرد على الملاحظات
            والأسئلة وبخلاف ذلك تقديم خدمة العملاء.
          </p>
          <p>تسهيل إجراء التعاملات وعمليات الدفع إن وجدت.</p>
          <p>
            تسهيل ربط حسابات وسائل التواصل الاجتماعي بخدماتنا لتقديم معلومات من
            تلك الحسابات إلى ملفك الشخصي. بناء على حسابات وسائل التواصل
            الاجتماعي التي تختارها ورهناً بإعدادات الخصوصية التي حددتها في هذه
            الحسابات، سنعمل على إدخال وإتاحة وتخزين (إن كان ذلك قابلاً للتطبيق
            ومصرحاً به من خلال خدمة وسائل التواصل الاجتماعي ومسموحاً به من قبلك)
            المعلومات في حسابات وسائل التواصل الاجتماعي الخاصة بك بحيث تكون
            متاحة على ومن خلال ملفك الشخصي الموجود على الخدمات؛
          </p>
          <p>
            تحقيق أغراض عملنا، مثل عمليات التدقيق، وتحقيق أغراض ضمان الجودة،
            لكشف الاحتيال ومنع حدوثه والاستجابة إلى المسائل التي قد تنشأ بخصوص
            الثقة والسلامة؛
          </p>
          <p>
            تحقيق أغراض الامتثال، بما في ذلك إنفاذ الأحكام والشروط الخاصة بنا أو
            غيرها من الحقوق القانونية، أو حسبما تفتضيه القوانين واللوائح المعمول
            بها، أو حسبما قد يكون مطلوباً بموجب أي إجراءات قضائية أو مطلوباً من
            قبل أي هيئة حكومية. ـ تحقيق أغراض أخرى نوجه بخصوصها إخطاراً محدداً
            وقت جمع المعلومات.
          </p>
          <p>
            تجميع أو بخلاف ذلك إلغاء تعريف المعلومات التي تم جمعها من خلال
            الخدمات واستخدامها وكشفها لأغراض تجارية أخرى بعد أن يتعذر ربط
            البيانات بصورة معقولة بشخص يمكن التعرف عليه؛ تسويق المنتجات والخدمات
            والإعلان عنها، بما في ذلك من خلال الاهتمامات التي تم استنتاجها من
            التفاعلات مع المواقع الالكترونية والتطبيقات الخاصة بنا.
          </p>
        </div>

        <div className="mb-4">
          <h4 className="h4t">مشاركة المعلومات:</h4>
          <p>
            نلتزم بالمحافظة على ثقتك، ونحتاج منك أن تتفهم وتستوعب متى ومع من
            يمكن أن نشارك المعلومات التي نجمعها. كما يمكن أن نشارك معلوماتك مع
            مقدمي الخدمات الهندسية المعتمدة والنقابة.
          </p>
          <p>
            موردي جهات خارجية ومقدمي خدمات مفوضين. يمكن أن نشارك معلوماتك مع
            موردي جهات خارجية ومقدمي خدمات يعملون على مساعدتنا في مجال الخدمات
            المتخصصة، بما في ذلك الفوترة، ومعالجة عمليات الدفع، وخدمة العملاء،
            ونشر الرسائل الالكترونية، وتحليل الأعمال، والتسويق (بما في ذلك دون
            حصر، الدعاية والإعلان، والتخصيص، والربط العميق، والتسويق على الهاتف
            المحمول، وتحقيق الأداء الأمثل، وإعادة الاستهداف)، والدعاية والإعلان،
            ومراقبة الأداء، والاستضافة، ومعالجة البيانات. لا يجوز لموردي الجهات
            الخارجية ومقدمي الخدمات المذكورين استخدام معلوماتك لأغراض بخلاف
            الأغراض المتعلقة بالخدمات التي يقدموها لنا.
          </p>
          <p>
            شركاء البحث. يمكن أن نشارك معلوماتك مع شركائنا في البحث لإجراء بحوث
            تتعلق بالمجال الهندسي.
          </p>
          <p>
            الشركات المنتسبة. يمكن أن نشارك معلوماتك مع شركاتنا المنتسبة التي
            تخضع لهذه السياسة.
          </p>
          <p>
            بموافقتك أو بتوجيه منك. يمكن أن نشارك معلوماتك لأي أغراض أخرى يتم
            اطلاعك عليها وقت قيامنا بجمع المعلومات أو بناء على موافقتك أو
            توجيهك.
          </p>
          <p>
            في حال دخولك إلى خدمات جهات خارجية، مثل فيسبوك أو جوجل أو ...الخ من
            خلال الخدمات لتسجيل الدخول إليها أو لمشاركة معلومات مع آخرين بشأن
            تجربتك معنا، فقد تتمكن تلك الخدمات من جمع معلومات عنك، بما في ذلك
            معلومات عن نشاطك على الموقع، كما يمكنهم الإبلاغ عن الاتصالات التي
            تجريها على خدمات الجهات الخارجية بخصوص استخدامك للموقع وذلك وفقاً
            لسياسات الخصوصية الخاصة بهم.
          </p>
          <p>
            إذا قررت المشاركة في أنشطة عامة على الموقع أو مواقع جهات خارجية
            نرتبط بها، فعليك أن تدرك بأن أي معلومات تشاركها على تلك المواقع يمكن
            أن تتم قراءتها أو جمعها أو استخدامها من قبل مستخدمين آخرين في تلك
            المساحات. يتوجب عليك توخي الحذر عند كشف معلومات شخصية أثناء المشاركة
            في تلك المساحات. لا نتحمل مسؤولية عن المعلومات التي تقرر تقديمها في
            تلك المساحات العامة.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="h4t">معلومات صورة المستخدمين:</h4>
          <p>
            <span className="h4t">التجميع:</span>
            يجمع الموقع والتطبيق معلومات عن صور المستخدمين في شكل صور للملف
            الشخصي. يتم جمع هذه المعلومات عندما يقوم المستخدمين بالتسجيل في
            الموقع والتطبيق.
          </p>
          <p>
            <span className="h4t">الاستعمال:</span>
            نستخدم معلومات صور المستخدمين لتخصيص تجربتهم مع الموقع والتطبيق.
            يتضمن ذلك عرض صور الملف الشخصي والسماح للمستخدمين بالتعرف على بعضهم
            بسهولة.
          </p>
          <p>
            <span className="h4t">المشاركة:</span>
            لا نشارك معلومات صور المستخدمين مع خدمات الجهات الخارجية والشركاء
            بغرض توفير وظائف الموقع والتطبيق لدينا.
          </p>
          <p>
            <h4 className="h4t">الافشاء تطبيقاً لأحكام القانون:</h4>
            <p>
              في ظل ظروف معينة قد يكون المطلوب منا أن نقدم معلوماتك الشخصية في
              حال تطلب ذلك القانون أو استجابة لطلب السلطات الحكومية النافذة (
              مثل المحكمة أو جهة حكومية )
            </p>
            <p>
              يمكن أن نقوم بتقديم معلوماتك الشخصية بحسن نية اذا كان هذا الفعل
              ضروري لـ :
            </p>
            <p>تطبيق الالتزامات القانونية</p>
            <p>
              حماية حقوق محتوى (موقع EyeEngineer) أو أي صلة بها والدفاع عنها
            </p>
            <p>للحد من أي فعل خاطىء متعلق بالموقع</p>
            <p>لحماية الأمن الشخصي لمستخدمي الموقع</p>
            <p>للحماية في مواجهة المسؤولية القانونية</p>
            <p>
              <h4 className="h4t">الحماية:</h4>
              نستخدم تدابير معقولة للمساعدة على حماية المعلومات من الفقد والسرقة
              وسوء الاستخدام والدخول غير المصرح به والكشف والتبديل والإتلاف.
              عليك أن تدرك أنه من غير الممكن ضمان توفير حماية كاملة لأي نظام
              تخزين بيانات أو نقل بيانات عبر الانترنت أو أي شبكة عامة أخرى. يرجى
              ملاحظة أن المعلومات التي يتم جمعها من قبل جهات خارجية قد لا تحظى
              بنفس درجة الحماية الأمنية التي تحظى بها المعلومات التي تقدمها لنا،
              ولا نتحمل مسؤولية عن حماية أمن هذه المعلومات.
            </p>
            <p>
              <h4 className="h4t">اختياراتك:</h4>
              كما يمكنك طلب أن نقوم نحن بإلغاء معلوماتك الشخصية بإرسال بريد
              الكتروني إلينا على info@eyeengineer.com يمكنك رفض أو تعطيل ملفات
              تعريف الارتباط من خلال تعديل إعدادات متصفح الويب الخاصة بك.
            </p>
            <p>
              <h4 className="h4t">شروط الانتساب لخدمة أضف مكتبك الالكتروني وفتح الحساب:</h4>

              <p>يجب أن يكون عمرك 25 سنة أو أكثر حسب القوانيين والأنظمة.</p>
              <p>
                أن يكون نشاط المكتب ضمن المجالات الهندسية المسموح بمزاولتها حصرا
                وفي حال خلاف ذلك يتم رفض الطلب.
              </p>
              <p>
                يجب عليك التسجيل في EyeEngineer لاستخدام الخدمة والاستفادة منها
                عن طريق التواصل مع فريق EyeEngineer عبر الايميل
                info@eyeengineer.com ويحق لـ EyeEngineer رفض طلب إنشاء أي حساب
                أو أضف مكتبك الكتروني، كما يحق لـ EyeEngineer انهاء خدمة أضف
                مكتبك الالكتروني أو أي حساب لأي تجاوز ضمن سياسة الموقع ودون سابق
                إنذار وفي أي وقت.
              </p>
            </p>
            <p>
              <h4 className="h4t">الدعاية والإعلان والمحتوى الخاص بالجهات الخارجية:</h4>
              <p>
                قد تحتوي بعض الخدمات على روابط لمحتوى محفوظ لدى جهات خارجية لا
                تخضع لمراقبتنا. نسمح لجهات خارجية بما في ذلك الشركاء التجاريين
                وشبكات الدعاية والإعلان ومقدمي خدمات الدعاية والإعلان الآخرين،
                بجمع معلومات عن الأنشطة التي تجريها عبر الانترنت من خلال ملفات
                تعريف الارتباط ووحدات البيكسل والتخزين المحلي وغيرها من
                التقنيات. يجوز لهذه الجهات الخارجية استخدام هذه المعلومات لعرض
                إعلانات على خدماتنا وفي أماكن أخرى عبر الانترنت مصممة لتتناسب مع
                اهتماماتك وتفضيلاتك وخصائصك. لا نتحمل مسؤولية عن ممارسات
                الخصوصية التي تتبعها هذه الجهات الخارجية، وممارسات المعلومات
                الخاصة بتلك الجهات غير مشمولة في سياسة الخصوصية هذه.
              </p>
              <p>
                تقوم بعض الجهات الخارجية بجمع معلومات عن مستخدمين لخدماتنا
                لتقديم إعلانات قائمة على الاهتمامات تعرضها على خدماتنا وأماكن
                أخرى، بما في ذلك عبر المتصفحات والأجهزة. يمكن لهذه الجهات
                الخارجية استخدام المعلومات التي تجمعها على خدماتنا لوضع توقعات
                بشأن اهتماماتك بغرض تزويدك بإعلانات (منا ومن شركات أخرى) عبر
                الانترنت. يمكن لبعض الجهات الخارجية المشاركة في نظام صناعي يتيح
                للمستخدمين اختيار عدم قبول تلقي إعلانات مصممة بناء على الأنشطة
                التي تجريها عبر الانترنت. نظراً للاختلافات بين استخدام التطبيقات
                والمواقع الالكترونية على أجهزة الهواتف المحمولة، قد تحتاج لاتخاذ
                خطوات إضافية لتعطيل تقنيات الإعلانات المستهدفة في تطبيقات الهاتف
                المحمول. يتيح لك العديد من أجهزة الهاتف المحمول اختيار عدم قبول
                الإعلانات المستهدفة من تطبيقات الهاتف المحمول باستخدام الإعدادات
                المضمنة في تطبيق الهاتف المحمول أو جهاز هاتفك المحمول. للحصول
                على مزيد من المعلومات، يرجى التحقق من إعدادات هاتفك المحمول.
                يمكنك أيضاً إلغاء تثبيت تطبيقاتنا باستخدام عملية إلغاء التثبيت
                المعيارية المتاحة على جهاز هاتفك المحمول أو متجر التطبيقات فيه.
              </p>
              <p>
                لا نسمح، عن سابق معرفة، للأشخاص غير المتخصصين والأكاديميين
                بإنشاء حسابات تتيح الدخول إلى خدماتنا.
              </p>
            </p>
          </p>
          <p>
            <h4 className="h4t">يمكن لإدارة الموقع:</h4>
            <p>
              تعديل أو تعليق أو إنهاء تشغيل أو الوصول إلى الموقع، أو أي جزء من
              الموقع، لأي سبب من الأسباب
            </p>
            <p>
              تعديل أو تغيير الموقع، أو أي جزء من الموقع، وأي سياسات أو بنود
              سارية.
            </p>
            <p>
              مقاطعة تشغيل الموقع، أو أي جزء من الموقع، حسب الضرورة لإجراء
              الصيانة الروتينية أو غير الروتينية.
            </p>
            <p>
              يحتفظ الموقع في الحق في جميع الأوقات في الكشف عن أي معلومات تعتقد
              أنها ضرورية للامتثال لأي قانون سارٍ أو لائحة أو إجراء قانوني أو
              طلب حكومي.
            </p>
            <p>
              يحق للموقع إ نهاء دخولك إلى الموقع و / أو حظر وصولك المستقبلي إلى
              الموقع إذا قررنا أنك انتهكت شروط الاستخدام هذه أو الاتفاقيات أو
              الإرشادات الأخرى.
            </p>{" "}
            <p>
              إذا اتخذ الموقع أي إجراء قانوني ضدك نتيجة لانتهاكك لشروط الاستخدام
              هذه، فسيحق له الاسترداد منك وأنت توافق على دفع، جميع أتعاب
              المحاماة المعقولة وتكاليف هذا الإجراء.
            </p>{" "}
            <p>
              <h4 className="h4t">روابط لمواقع أخرى:</h4>
              <p>
                قد يحتوي الموقع على روابط لمواقع أخرى لا يتم تشغيلها من قبلنا،
                نحن ننصحك بأن تعمل على مراجعة سياسة الخصوصية لكل موقع الكتروني
                تزوره، نحن لا نملك السيطرة ولا نتحمل المسؤولية على المحتوى أو
                سياسات الخصوصية أو ممارسات مواقع الأطراف الثالثة وخدماتها.
              </p>
              <p>التعديل على سياسة الخصوصية:</p>
              <p>
                يمكن أن نُحدّث سياسة الخصوصية هذه من وقت لآخر. عند قيامنا بتحديث
                سياسة الخصوصية، سنُعدّل "تاريخ النفاذ" الوارد أعلاه وننشر سياسة
                الخصوصية الجديدة. وسيكون معمول بها من تاريخ نشرها على الموقع،
                ننصحك بمراجعة سياسة الخصوصية في كل مرة تزور فيها الخدمات لتبقى
                على اطلاع بممارسات الخصوصية الخاصة بنا.
              </p>
            </p>
            <p>
              <h4 className="h4t">حقوق الملكية الفكرية</h4>
              <p>
                هذا الموقع وجميع محتوياته بما في ذلك على سبيل المثال لا الحصر
                جميع النصوص والرسومات ورموز html جميع أسماء المنتجات والأسماء
                التجارية وأسماء الخدمات وأسماء المجالات وخطوط العلامات أو الشعار
                المميز في الشكل أو النص أو غير ذلك من النصوص المحيطة هي مواد
                محمية بموجب حقوق الطبع والنشر ولا يجوز طباعتها أو نسخها أو نشرها
                أو إعادة إنتاجها أو استخدامها (بما في ذلك صور الوجوه) أو توزيعها
                كلياً أو جزئياً دون الحصول على موافقة كتابية مسبقة من Eye
                Engineer
              </p>
            </p>
            <p>
              <h4 className="h4t">أسئلة؟</h4>
              <p>
                إن كان لديك أي أسئلة حول سياسة الخصوصية هذه أو حول ممارساتنا،
                يرجى إرسال بريد الكتروني لنا على <i>info@eyeengineer.com</i>
              </p>
            </p>
          </p>
        </div>
      </div>
    </>
  );
};

export default ArabicPolicy;
