import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      English: "English",
      Turkish: "Turkish",
      Arabic: "Arabic",
      German: "German",
      Home: "Home",
      "About Us": "About Us",
      Capabilities: "Capabilities",
      "Contact Us": "Contact Us",
      "Sign Up": "Sign Up",
      Login: "Login",
      Geometries: "Geometries",
      GeometriesDescription:
        "Contains diverse engineering information and resources",
      "Educational Videos": "Educational Videos",
      EducationalVideosDescription:
        "Provides a collection of instructional videos",
      "Engineering Articles": "Engineering Articles",
      EngineeringArticlesDescription: "Contains diverse articles",
      Recents: "Recents",
      RecentsDescription: "Provides the latest information and news",
      Terminologies: "Terminologies",
      TerminologiesDescription:
        "Provides definitions of technical and professional terms",
      Offices: "Offices",
      OfficesDescription:
        "Offers tips and ideas to enhance the work environment in offices",
      View: "View",
      FooterDescription:
        "Explore engineering and related information, Files and resources for various engineering disciplines.",
      CreatedBy: "Created By",
      Copyright: "&copy; 2022 - ",
      "Useful Links": "Useful Links",
      "Reserve Office": "Reserve Office",
      "Intelligent Translator": "Intelligent Translator",
      "Privacy Policy": "Privacy Policy",
      "Articles Policy": "Articles Policy",
      "Questions Policy": "Questions Policy",
      "Our Services": "Our Services",
      "Property Valuation": "Property Valuation",
      ContactDescription:
        "Get in touch with us via mail phone.We are waiting for your call or message",
      Email_text: "info@eyeengineer.com",
      "Get In Touch": "Get In Touch",
      "Opening Hours": "Opening Hours",
      Whatsapp: "Whatsapp",
      Address: "Address",
      subject: "Subject",
      content: "Content",
      "Send Message": "Send Message",
      "09:00am to 06:00pm": "09:00am to 06:00pm",
      Syria: "Syria",
      Services: "Our Popular Services",
      OurCapabilities: "Our Capabilities",
      "Email is required": "Email is required",
      "Password is required": "Password is required",
      "Sign in": "Sign in",
      Email: "Email",
      Password: "Password",
      "Forgot password?": "Forgot password?",
      "Don't have an account?": "Don't have an account?",
      Register: "Register",
      Logout: "Logout",
      "Create an Account": "Create an Account",
      "User Name": "User Name",
      "Confirm Password": "Confirm Password",
      SignUp: "Sign Up",
      "User Name is required": "User Name is required",
      "Confirm Password is required": "Confirm Password is required",
      "Passwords do not match": "Passwords do not match",
      "The email has already been taken.": "The email has already been taken.",
      valuation_title: "Property valuation",
      valuationDescription: "Provides property valuations by experts",
      valuation_d: "Take an evaluation from the best experts",
      contact_phone: "contact_phone",
      EngineeringArticles: "Engineering Articles",
      "Message sent successfully!": "Message sent successfully",
      text: "text",
      "Select Source Language": "Select Source Language",
      "Select Target Language": "Select Target Language",
      translate: "translate",
      PrivacyPolicy: "Privacy Policy",
      TerminologiesC: "Categries",
      Classifications: "Classifications",
      Articles: "Articles",
      Office_Reservation: "Office Reservation",
      dr: "Reserve a space at our website to showcase office information and files. Our website provides customers with the ability to book a spot specifically for displaying office details and related documents. Whether you want to showcase office specifications, images, or other relevant information, our reservation system allows you to secure a designated area for this purpose. Reserve your space now to effectively present your office and its associated materials.",
      office_name: "office name",
      office_description: "office description",
      checkcode: "Check your email and enter the code",
      Accept: "Accept",
      Code: "Code",
      "code is required": "code is required",
      "The code is wrong": "The code is wrong",
      profile: "Profile",
      Dashboard: "Dashboard",
      "Enter your email": "Enter your email",
      "The Email not found": "Account is not found",
      passwordUpdate: "The password has been updated",
      golo: "Go to log in again",
      Delete: "Delete",
      "Logged out successfully": "Logged out successfully",
      "Account deleted successfully": "Account deleted successfully",
      "Account successfully created": "Account successfully created",
      "logged in successfully": "logged in successfully",
      "Profile modified successfully": "Profile modified successfully",
      "Edit Profile": "Edit Profile",
      Edit: "Edit",
      VIDEOS: "Videos",
      AUDIOS: "Audios",
      IMAGES: "Images",
      PDF: "Pdf",
      ZIP: "Zip",
      "Videos are not found": "Videos are not found",
      "Recents are not found": "Data are not found",
      "Geometries are not found": "Geometries are not found",
      "Offices are not found": "Offices are not found",
      "Terminologies are not found": "Terminologies are not found",
      "Categories are not found": "Categories are not found",
      "Classifications are not found": "Classifications are not found",
      "Links are not found": "Links are not found",
      "Articles are not found": "Articles are not found",
      Reserve: "Reserve",
      "The office is blocked": "The office is blocked",
      files: "files",
      links: "links",
      articles: "articles",
      Users: "Users",
      Slides: "Slides",
      News: "News",
      Reservations: "Reservations",
      Messages: "Messages",
      "My Offices": "My Offices",
      Forbidden: "Forbidden",
      search: "search",
      Name: "Name",
      Image: "Image",
      Phone: "Phone",
      Experiences: "Experiences",
      Title: "Title",
      Url: "Url",
      Text: "Text",
      Type: "Type",
      "Add file": "Add File",
      "Update file": "Update File",
      Save: "Save",
      "Upload file": "Upload file",
      Role: "Role",
      Admin: "Admin",
      User: "User",
      "Choose A Role": "Choose A Role",
      "Update User": "Update User",
      "Add Slider": "Add Slider",
      "Update Slider": "Update Slider",
      New: "New",
      "Add New": "Add New",
      "Update New": "Update New",
      Service: "Service",
      "show able": "show able",
      Subject: "Subject",
      Content: "Content",
      Description: "Description",
      Priority: "Priority",
      "Add Geometry": "Add Geometry",
      "Update Geometry": "Update Geometry",
      Geometry: "Geometry",
      "Add Classification": "Add Classification",
      "Update Classification": "Update Classification",
      Video: "Video",
      "Upload Video": "Upload Video",
      "Add Video": "Add Video",
      "Update Video": "Update Video",
      "Add Recent": "Add Recent",
      "Update Recent": "Update Recent",
      Meaning: "Meaning",
      Field: "Field",
      "Terminologies Categories": "Terminologies Categories",
      Terminologie: "Terminologie",
      "Add Terminology": "Add Terminology",
      "Add Category": "Add Category",
      "Update Terminology": "Update Terminology",
      "Update Category": "Update Category",
      "Update Office": "Update Office",
      "Add Office": "Add Office",
      Add: "Add",
      Remove: "Remove",
      "Enter service": "Enter service",
      "Enter experience": "Enter experience",
      "Update Link": "Update Link",
      "Add Link": "Add Link",
      "Update Article": "Update Article",
      "Add Article": "Add Article",
      services: "Services",
      'Change Email':'Change Email',
      'Classification':'Classification'

    },
  },
  ar: {
    translation: {
      English: "الإنكليزية",
      Turkish: "التركية",
      German: "الألمانية",
      Arabic: "العربية",
      Home: "الصفحة الرئيسية",
      "About Us": "معلومات عنا",
      Capabilities: "القدرات",
      "Contact Us": "اتصل بنا",
      "Sign Up": "انشاء حساب",
      Login: "تسجيل الدخول",
      Geometries: "الهندسات",
      GeometriesDescription: "يحتوي على معلومات وموارد هندسية متنوعة",
      "Educational Videos": "فيديوهات تعليمية",
      EducationalVideosDescription: "يوفر مجموعة من الفيديوهات التعليمية",
      "Engineering Articles": "مقالات هندسية",
      EngineeringArticlesDescription: "يحتوي على مقالات متنوعة",
      Recents: "الأحدث",
      RecentsDescription: "يوفر أحدث المعلومات والأخبار",
      Terminologies: "المصطلحات",
      TerminologiesDescription: "يوفر تعريفات للمصطلحات الفنية والمهنية",
      Offices: "المكاتب",
      OfficesDescription: "يقدم نصائح وأفكار لتحسين بيئة العمل في المكاتب",
      View: "عرض",
      FooterDescription:
        "استكشف المعلومات الهندسية والملفات والموارد المتعلقة بمختلف التخصصات الهندسية.",
      CreatedBy: "تم الإنشاء بواسطة",
      Copyright: "&copy; 2022 - ",
      "Useful Links": "روابط مفيدة",
      "Reserve Office": "حجز مكتب",
      "Intelligent Translator": "المترجم الذكي",
      "Privacy Policy": "سياسة الخصوصية",
      "Articles Policy": "سياسة المقالات",
      "Questions Policy": "سياسة الأسئلة",
      "Our Services": "خدماتنا",
      "Property Valuation": "تقييم العقارات",
      ContactDescription:
        "تواصل معنا عبر البريد الإلكتروني أو الهاتف. نحن في انتظار مكالمتك أو رسالتك",
      Email_text: "info@eyeengineer.com",
      "Get In Touch": "تواصل معنا",
      "Opening Hours": "ساعات العمل",
      Whatsapp: "واتساب",
      Address: "العنوان",
      subject: "الموضوع",
      content: "المحتوى",
      "Send Message": "إرسال الرسالة",
      "09:00am to 06:00pm": "09:00 صباحاً إلى 06:00 مساءً",
      Syria: "سوريا",
      Services: "خدماتنا",
      OurCapabilities: "قدراتنا",
      "Email is required": "البريد الإلكتروني مطلوب",
      "Password is required": "كلمة المرور مطلوبة",
      "Sign in": "تسجيل الدخول",
      Email: "البريد الإلكتروني",
      Password: "كلمة المرور",
      "Forgot password?": "هل نسيت كلمة المرور؟",
      "Don't have an account?": "ليس لديك حساب؟",
      Register: "سجل",
      Logout: "الخروج",
      "Create an Account": "إنشاء حساب",
      "User Name": "اسم المستخدم",
      "Confirm Password": "تأكيد كلمة المرور",
      SignUp: "تسجيل",
      "User Name is required": "اسم المستخدم مطلوب",
      "Confirm Password is required": "تأكيد كلمة المرور مطلوب",
      "Passwords do not match": "كلمات المرور غير متطابقة",
      "The email has already been taken.": "البريد الإلكتروني مستخدم بالفعل",
      valuation_title: "التقييم العقاري",
      valuationDescription: "يوفر تقييمات للممتلكات من قبل مختصين",
      valuation_d: "احصل على التقييم من افضل الخبراء",
      contact_phone: "رقم التواصل",
      EngineeringArticles: "مقالات هندسية",
      "Message sent successfully!": "الارسال تم بنجاح",
      text: "النص",
      "Select Source Language": "اللغة المصدر",
      "Select Target Language": "اللغة الهدف",
      translate: "ترجمة",
      TerminologiesC: "اصناف",
      Classifications: "التصنيفات",
      Articles: "المقالات",
      Office_Reservation: "حجز مكتب",
      dr: "قم بحجز مساحة في موقعنا لعرض معلومات المكتب وملفاته. يوفر موقعنا الإلكتروني للعملاء إمكانية حجز مكان خاص لعرض تفاصيل المكتب والوثائق ذات الصلة. سواء كنت ترغب في عرض مواصفات المكتب، أو الصور، أو معلومات أخرى ذات الصلة، فإن نظام الحجز الخاص بنا يتيح لك حجز مساحة محددة لهذا الغرض. احجز مساحتك الآن لتقديم مكتبك ومواده المرتبطة بشكل فعال.",
      office_name: "اسم المكتب",
      office_description: "وصف المكتب",
      checkcode: "تحقق من بريدك الإلكتروني وأدخل الرمز",
      Accept: "تأكيد",
      Code: "الرمز",
      "code is required": "الرمز مطلوب ادخاله",
      "The code is wrong": "الرمز خطأ",
      profile: "الملف الشخصي",
      Dashboard: "لوحة التحكم",
      "Enter your email": "أدخل بريدك الإلكتروني",
      "The Email not found": "الحساب غير موجود",
      passwordUpdate: "تم تحديث كلمة المرور",
      golo: "اذهب لتسجيل الدخول مرة اخرى",
      Delete: "حذف",
      "Logged out successfully": "تم تسجيل الخروج",
      "Account deleted successfully": "تم حذف الحساب بنجاح",
      "Account successfully created": "تم انشاء الحساب بنجاح",
      "logged in successfully": "تم تسجيل الدخول بنجاح",
      "Profile modified successfully": "تم تعديل الملف الشخصي بنجاح",
      "Edit Profile": "تعديل الملف الشخصي",
      Edit: "تعديل",
      VIDEOS: "فيديوهات",
      AUDIOS: "صوتيات",
      IMAGES: "صور",
      PDF: "ملفات محمولة",
      ZIP: "ملفات مضغوطة",
      "Videos are not found": "لا توجد فيديوهات",
      "Recents are not found": "لا توجد بيانات",
      "Geometries are not found": "لا توجد هندسات",
      "Offices are not found": "المكاتب غير موجودة",
      "Terminologies are not found": "لا توجد مصطلحات",
      "Categories are not found": "لا توجد اصناف",
      "Classifications are not found": "لا توجد تصنيفات",
      "Links are not found": "لا توجد روابط",
      "Articles are not found": "لا توجد مقالات",
      Reserve: "احجز",
      "The office is blocked": "المكتب محظور",
      files: "ملفات",
      links: "روابط",
      articles: "مقالات",
      Users: "المستخدمين",
      Slides: "شرائح",
      News: "اخبار",
      Reservations: "حجوزات",
      Messages: "رسائل",
      "My Offices": "مكاتبي",
      Forbidden: "محظور",
      search: "بحث",
      Name: "اسم",
      Image: "صورة",
      Phone: "موبايل",
      Experiences: "خبرات",
      Title: "عنوان",
      Url: "رابط",
      Text: "نص",
      Type: "نوع",
      "Add file": "اضافة ملف",
      "Update file": "تعديل ملف",
      Save: "حفظ",
      "Upload file": "تحميل ملف",
      Role: "رتبة",
      "Upload Image": "تحميل صورة",
      "Add User": "اضافة مستخدم",
      Admin: "ادمن",
      User: "مستخدم",
      "Choose A Role": "اختر رتبة",
      "Update User": "تعديل المستخدم",
      "Add Slider": "اضافة شريحة",
      "Update Slider": "تعديل شريحة",
      New: "الخبر",
      "Add New": "اضافة خبر",
      "Update New": "تعديل خبر",
      Service: "الخدمة",
      "show able": "امكانية العرض",
      Subject: "الموضوع",
      Content: "المحتوى",
      Description: "الوصف",
      Priority: "اولوية",
      "Add Geometry": "اضافة هندسة",
      "Update Geometry": "تعديل هندسة",
      Geometry: "هندسة",
      "Add Classification": "اضافة صنف",
      "Update Classification": "تعديل صنف",
      Video: "فيديو",
      "Upload Video": "تحميل فيديو",
      "Add Video": "اضافة فيديو",
      "Update Video": "تعديل فيديو",
      "Add Recent": "اضافة",
      "Update Recent ": "تعديل",
      Meaning: "معنى",
      Field: "حقل",
      "Terminologies Categories": "اصناف المصطلحات",
      Terminologie: "المصطلح",
      "Add Terminology": "اضافة مصطلح",
      "Add Category": "اضافة صنف",
      "Update Terminology": "تعديل مصطلح",
      "Update Category": "تعديل صنف",
      "Update Office": "تعديل مكتب",
      "Add Office": "اضافة مكتب",
      Add: "اضافة",
      Remove: "حذف",
      "Enter service": "ادخل خدمة",
      "Enter experience": "ادخل خبرة",
      "Update Link": "تعديل رابط",
      "Add Link": "اضافة رابط",
      "Update Article": "تعديل مقالة",
      "Add Article": "اضافة مقالة",
      services: "الخدمات",
      'Change Email':'تغيير البريد الالكتروني',
      "Classification": "التصنيف"
    },
  },
  tr: {
    translation: {
      English: "İngilizce",
      Turkish: "Türkçe",
      Arabic: "Arapça",
      German: "Almanca",
      Home: "Ana Sayfa",
      "About Us": "Hakkımızda",
      Capabilities: "Yetenekler",
      "Contact Us": "Bize Ulaşın",
      "Sign Up": "Kayıt Ol",
      Login: "Giriş Yap",
      Geometries: "Geometriler",
      GeometriesDescription:
        "Çeşitli mühendislik bilgileri ve kaynaklarını içerir",
      "Educational Videos": "Eğitici Videolar",
      EducationalVideosDescription: "Eğitici videolar koleksiyonu sunar",
      "Engineering Articles": "Mühendislik Makaleleri",
      EngineeringArticlesDescription: "Çeşitli makaleler içerir",
      Recents: "Son Eklenenler",
      RecentsDescription: "En son bilgileri ve haberleri sağlar",
      Terminologies: "Terminolojiler",
      TerminologiesDescription:
        "Teknik ve profesyonel terimlerin tanımlarını sağlar",
      Offices: "Ofisler",
      OfficesDescription:
        "Ofis ortamını geliştirmek için ipuçları ve fikirler sunar",
      View: "Görüntüle",
      FooterDescription:
        "Çeşitli mühendislik disiplinlerine ait dosya ve kaynaklarla mühendislik ve ilgili bilgileri keşfedin.",
      CreatedBy: "Oluşturan",
      Copyright: "&copy; 2022 - ",
      "Useful Links": "Faydalı Linkler",
      "Reserve Office": "Ofis Rezervasyonu",
      "Intelligent Translator": "Akıllı Çevirmen",
      "Privacy Policy": "Gizlilik Politikası",
      "Articles Policy": "Makaleler Politikası",
      "Questions Policy": "Sorular Politikası",
      "Our Services": "Hizmetlerimiz",
      "Property Valuation": "Gayrimenkul",
      ContactDescription:
        "Bizimle e-posta veya telefon yoluyla iletişime geçin. Çağrınızı veya mesajınızı bekliyoruz.",
      Email_text: "info@eyeengineer.com",
      "Get In Touch": "İletişime Geçin",
      "Opening Hours": "Çalışma Saatleri",
      Whatsapp: "Whatsapp",
      Address: "Adres",
      subject: "Konu",
      content: "İçerik",
      "Send Message": "Mesaj Gönder",
      "09:00am to 06:00pm": "09:00 - 18:00",
      Syria: "Suriye",
      Services: "Popüler Hizmetlerimiz",
      OurCapabilities: "Yetkinliklerimiz",
      "Email is required": "E-posta gereklidir",
      "Password is required": "Şifre gereklidir",
      "Sign in": "Giriş Yap",
      Email: "E-posta",
      Password: "Şifre",
      "Forgot password?": "Şifrenizi mi unuttunuz?",
      "Don't have an account?": "Hesabınız yok mu?",
      Register: "Kayıt Ol",
      Logout: "ÇıkışYap",
      "Create an Account": "Hesap Oluştur",
      "User Name": "Kullanıcı Adı",
      "Confirm Password": "Şifreyi Onayla",
      SignUp: "Kayıt Ol",
      "User Name is required": "Kullanıcı adı gereklidir",
      "Confirm Password is required": "Şifre onayı gereklidir",
      "Passwords do not match": "Şifreler uyuşmuyor",
      "The email has already been taken.": "Bu e-posta zaten alınmış.",
      valuation_title: "Gayrimenkul değerleme",
      valuationDescription:
        "Uzmanlar tarafından gayrimenkul değerleme hizmetleri sunar",
      valuation_d: "En iyi uzmanlardan değerlendirme alın",
      contact_phone: "İletişim Telefonu",
      EngineeringArticles: "Mühendislik Makaleleri",
      "Message sent successfully!": "Mesaj başarıyla gönderildi",
      text: "metin",
      "Select Source Language": "Kaynak Dili Seçin",
      "Select Target Language": "Hedef Dili Seçin",
      translate: "çevir",
      PrivacyPolicy: "Gizlilik Politikası",
      TerminologiesC: "Kategoriler",
      Classifications: "Sınıflandırmalar",
      Articles: "Makaleler",
      Office_Reservation: "Ofis Rezervasyonu",
      dr: "Ofis bilgilerini ve dosyalarını sergilemek için sitemizde bir alan ayırın. Ofis bilgilerini, resimlerini veya ilgili belgeleri sergilemek için sitemizin rezervasyon sistemini kullanarak bir alan ayırabilirsiniz.",
      office_name: "Ofis adı",
      office_description: "Ofis açıklaması",
      checkcode: "E-postanızı kontrol edin ve kodu girin",
      Accept: "Kabul Et",
      Code: "Kod",
      "code is required": "Kod gereklidir",
      "The code is wrong": "Kod yanlış",
      profile: "Profil",
      Dashboard: "Gösterge Paneli",
      "Enter your email": "E-posta adresinizi girin",
      "The Email not found": "Hesap bulunamadı",
      passwordUpdate: "Şifre güncellendi",
      golo: "Tekrar giriş yapmak için tıklayın",
      Delete: "Sil",
      "Logged out successfully": "Başarıyla çıkış yapıldı",
      "Account deleted successfully": "Hesap başarıyla silindi",
      "Account successfully created": "Hesap başarıyla oluşturuldu",
      "logged in successfully": "Başarıyla giriş yapıldı",
      "Profile modified successfully": "Profil başarıyla güncellendi",
      "Edit Profile": "Profili Düzenle",
      Edit: "Düzenle",
      VIDEOS: "Videolar",
      AUDIOS: "Ses Dosyalari",
      IMAGES: "Resimler",
      PDF: "Pdf",
      ZIP: "Zip",
      "Videos are not found": "Videolar bulunamadı",
      "Recents are not found": "Veri bulunamadı",
      "Geometries are not found": "Geometriler bulunamadı",
      "Offices are not found": "Ofisler bulunamadı",
      "Terminologies are not found": "Terminolojiler bulunamadı",
      "Categories are not found": "Kategoriler bulunamadı",
      "Classifications are not found": "Sınıflandırmalar bulunamadı",
      "Links are not found": "Linkler bulunamadı",
      "Articles are not found": "Makaleler bulunamadı",
      Reserve: "Rezerve et",
      "The office is blocked": "Ofis bloke edilmiş",
      files: "dosyalar",
      links: "linkler",
      articles: "makaleler",
      Users: "Kullanıcılar",
      Slides: "Slaytlar",
      News: "Haberler",
      Reservations: "Rezervasyonlar",
      Messages: "Mesajlar",
      "My Offices": "Ofislerim",
      Forbidden: "Yasak",
      search: "ara",
      Name: "İsim",
      Image: "Resim",
      Phone: "Telefon",
      Experiences: "Deneyimler",
      Title: "Başlık",
      Url: "Url",
      Text: "Metin",
      Type: "Tür",
      "Add file": "Dosya Ekle",
      "Update file": "Dosya Güncelle",
      Save: "Kaydet",
      "Upload file": "Dosya Yükle",
      Role: "Rol",
      Admin: "Yönetici",
      User: "Kullanıcı",
      "Choose A Role": "Bir Rol Seçin",
      "Update User": "Kullanıcıyı Güncelle",
      "Add Slider": "Slayt Ekle",
      "Update Slider": "Slaytı Güncelle",
      New: "Yeni",
      "Add New": "Yeni Ekle",
      "Update New": "Yeni Güncelle",
      Service: "Hizmet",
      "show able": "görüntülenebilir",
      Subject: "Konu",
      Content: "İçerik",
      Description: "Açıklama",
      Priority: "Öncelik",
      "Add Geometry": "Geometri Ekle",
      "Update Geometry": "Geometri Güncelle",
      Geometry: "Geometri",
      "Add Classification": "Sınıflandırma Ekle",
      "Update Classification": "Sınıflandırmayı Güncelle",
      Video: "Video",
      "Upload Video": "Video Yükle",
      "Add Video": "Video Ekle",
      "Update Video": "Videoyu Güncelle",
      "Add Recent": "Son Eklenen Ekle",
      "Update Recent": "Son Eklenen Güncelle",
      Meaning: "Anlam",
      Field: "Alan",
      "Terminologies Categories": "Terminoloji Kategorileri",
      Terminologie: "Terminoloji",
      "Add Terminology": "Terminoloji Ekle",
      "Add Category": "Kategori Ekle",
      "Update Terminology": "Terminolojiyi Güncelle",
      "Update Category": "Kategoriyi Güncelle",
      "Update Office": "Ofisi Güncelle",
      "Add Office": "Ofis Ekle",
      Add: "Ekle",
      Remove: "Kaldır",
      "Enter service": "Hizmeti girin",
      "Enter experience": "Deneyimi girin",
      "Update Link": "Linki Güncelle",
      "Add Link": "Link Ekle",
      "Update Article": "Makaleyi Güncelle",
      "Add Article": "Makale Ekle",
      services: "Hizmetler",
     'Change Email':'E-postayı Değiştir',
     "Classification": "Sınıflandırma"
    },
  },

  de: {
    translation: {
      English: "Englisch",
      Turkish: "Türkisch",
      Arabic: "Arabisch",
      German: "Deutsch",
      Home: "Startseite",
      "About Us": "Über uns",
      Capabilities: "Fähigkeiten",
      "Contact Us": "Kontaktieren Sie uns",
      "Sign Up": "Registrieren",
      Login: "Anmelden",
      Geometries: "Geometrien",
      GeometriesDescription:
        "Enthält verschiedene technische Informationen und Ressourcen",
      "Educational Videos": "Lehrvideos",
      EducationalVideosDescription: "Bietet eine Sammlung von Lehrvideos",
      "Engineering Articles": "Ingenieurartikel",
      EngineeringArticlesDescription: "Enthält verschiedene Artikel",
      Recents: "Neuigkeiten",
      RecentsDescription: "Bietet die neuesten Informationen und Nachrichten",
      Terminologies: "Terminologien",
      TerminologiesDescription:
        "Bietet Definitionen technischer und beruflicher Begriffe",
      Offices: "Büros",
      OfficesDescription:
        "Bietet Tipps und Ideen zur Verbesserung des Arbeitsumfelds in Büros",
      View: "Ansehen",
      FooterDescription:
        "Erkunden Sie technische und verwandte Informationen. Dateien und Ressourcen für verschiedene Ingenieurdisziplinen.",
      CreatedBy: "Erstellt von",
      Copyright: "&copy; 2022 - ",
      "Useful Links": "Nützliche Links",
      "Reserve Office": "Büro reservieren",
      "Intelligent Translator": "Intelligenter Übersetzer",
      "Privacy Policy": "Datenschutzrichtlinie",
      "Articles Policy": "Artikelrichtlinie",
      "Questions Policy": "Fragenrichtlinie",
      "Our Services": "Unsere Dienstleistungen",
      "Property Valuation": "Immobilienbewertung",
      ContactDescription:
        "Kontaktieren Sie uns per E-Mail oder Telefon. Wir warten auf Ihren Anruf oder Ihre Nachricht.",
      Email_text: "info@eyeengineer.com",
      "Get In Touch": "Kontakt aufnehmen",
      "Opening Hours": "Öffnungszeiten",
      Whatsapp: "Whatsapp",
      Address: "Adresse",
      subject: "Betreff",
      content: "Inhalt",
      "Send Message": "Nachricht senden",
      "09:00am to 06:00pm": "09:00 bis 18:00 Uhr",
      Syria: "Syrien",
      Services: "Unsere beliebten Dienstleistungen",
      OurCapabilities: "Unsere Fähigkeiten",
      "Email is required": "E-Mail ist erforderlich",
      "Password is required": "Passwort ist erforderlich",
      "Sign in": "Anmelden",
      Email: "E-Mail",
      Password: "Passwort",
      "Forgot password?": "Passwort vergessen?",
      "Don't have an account?": "Kein Konto?",
      Register: "Registrieren",
      Logout: "Abmelden",
      "Create an Account": "Konto erstellen",
      "User Name": "Benutzername",
      "Confirm Password": "Passwort bestätigen",
      SignUp: "Registrieren",
      "User Name is required": "Benutzername ist erforderlich",
      "Confirm Password is required": "Passwortbestätigung ist erforderlich",
      "Passwords do not match": "Passwörter stimmen nicht überein",
      "The email has already been taken.":
        "Die E-Mail-Adresse ist bereits vergeben.",
      valuation_title: "Immobilienbewertung",
      valuationDescription: "Bietet Immobilienbewertungen von Experten",
      valuation_d: "Lassen Sie sich von den besten Experten bewerten",
      contact_phone: "Kontakttelefon",
      EngineeringArticles: "Ingenieurartikel",
      "Message sent successfully!": "Nachricht erfolgreich gesendet!",
      text: "Text",
      "Select Source Language": "Quellsprache auswählen",
      "Select Target Language": "Zielsprache auswählen",
      translate: "übersetzen",
      PrivacyPolicy: "Datenschutzrichtlinie",
      TerminologiesC: "Kategorien",
      Classifications: "Klassifikationen",
      Articles: "Artikel",
      Office_Reservation: "Büroreservierung",
      dr: "Reservieren Sie einen Platz auf unserer Website, um Büroinformationen und Dateien anzuzeigen. Unser Reservierungssystem ermöglicht es Ihnen, einen speziellen Bereich für die Präsentation von Bürodetails und zugehörigen Dokumenten zu buchen. Ob Sie Bürospezifikationen, Bilder oder andere relevante Informationen präsentieren möchten, unser System bietet Ihnen die Möglichkeit, einen Bereich zu sichern. Reservieren Sie jetzt Ihren Platz, um Ihr Büro und seine Materialien effektiv zu präsentieren.",
      office_name: "Büroname",
      office_description: "Bürobeschreibung",
      checkcode: "Überprüfen Sie Ihre E-Mails und geben Sie den Code ein",
      Accept: "Akzeptieren",
      Code: "Code",
      "code is required": "Code ist erforderlich",
      "The code is wrong": "Der Code ist falsch",
      profile: "Profil",
      Dashboard: "Dashboard",
      "Enter your email": "Geben Sie Ihre E-Mail-Adresse ein",
      "The Email not found": "Konto wurde nicht gefunden",
      passwordUpdate: "Das Passwort wurde aktualisiert",
      golo: "Klicken Sie hier, um sich erneut anzumelden",
      Delete: "Löschen",
      "Logged out successfully": "Erfolgreich abgemeldet",
      "Account deleted successfully": "Konto erfolgreich gelöscht",
      "Account successfully created": "Konto erfolgreich erstellt",
      "logged in successfully": "Erfolgreich angemeldet",
      "Profile modified successfully": "Profil erfolgreich geändert",
      "Edit Profile": "Profil bearbeiten",
      Edit: "Bearbeiten",
      VIDEOS: "Video",
      AUDIOS: "Audios",
      IMAGES: "Bilder",
      PDF: "Pdf",
      ZIP: "Zip",
      "Videos are not found": "Videos nicht gefunden",
      "Recents are not found": "Keine Daten gefunden",
      "Geometries are not found": "Geometrien nicht gefunden",
      "Offices are not found": "Büros nicht gefunden",
      "Terminologies are not found": "Terminologien nicht gefunden",
      "Categories are not found": "Kategorien nicht gefunden",
      "Classifications are not found": "Klassifikationen nicht gefunden",
      "Links are not found": "Links nicht gefunden",
      "Articles are not found": "Artikel nicht gefunden",
      Reserve: "Reservieren",
      "The office is blocked": "Das Büro ist blockiert",
      files: "Dateien",
      links: "Links",
      articles: "Artikel",
      Users: "Benutzer",
      Slides: "Folien",
      News: "Nachrichten",
      Reservations: "Reservierungen",
      Messages: "Nachrichten",
      "My Offices": "Meine Büros",
      Forbidden: "Verboten",
      search: "Suche",
      Name: "Name",
      Image: "Bild",
      Phone: "Telefon",
      Experiences: "Erfahrungen",
      Title: "Titel",
      Url: "Url",
      Text: "Text",
      Type: "Typ",
      "Add file": "Datei hinzufügen",
      "Update file": "Datei aktualisieren",
      Save: "Speichern",
      "Upload file": "Datei hochladen",
      Role: "Rolle",
      Admin: "Administrator",
      User: "Benutzer",
      "Choose A Role": "Wählen Sie eine Rolle",
      "Update User": "Benutzer aktualisieren",
      "Add Slider": "Slider hinzufügen",
      "Update Slider": "Slider aktualisieren",
      New: "Neu",
      "Add New": "Neu hinzufügen",
      "Update New": "Neu aktualisieren",
      Service: "Dienstleistung",
      "show able": "sichtbar",
      Subject: "Betreff",
      Content: "Inhalt",
      Description: "Beschreibung",
      Priority: "Priorität",
      "Add Geometry": "Geometrie hinzufügen",
      "Update Geometry": "Geometrie aktualisieren",
      Geometry: "Geometrie",
      "Add Classification": "Klassifikation hinzufügen",
      "Update Classification": "Klassifikation aktualisieren",
      Video: "Video",
      "Upload Video": "Video hochladen",
      "Add Video": "Video hinzufügen",
      "Update Video": "Video aktualisieren",
      "Add Recent": "Neuigkeiten hinzufügen",
      "Update Recent": "Neuigkeiten aktualisieren",
      Meaning: "Bedeutung",
      Field: "Feld",
      "Terminologies Categories": "Terminologien Kategorien",
      Terminologie: "Terminologie",
      "Add Terminology": "Terminologie hinzufügen",
      "Add Category": "Kategorie hinzufügen",
      "Update Terminology": "Terminologie aktualisieren",
      "Update Category": "Kategorie aktualisieren",
      "Update Office": "Büro aktualisieren",
      "Add Office": "Büro hinzufügen",
      Add: "Hinzufügen",
      Remove: "Entfernen",
      "Enter service": "Dienstleistung eingeben",
      "Enter experience": "Erfahrung eingeben",
      "Update Link": "Link aktualisieren",
      "Add Link": "Link hinzufügen",
      "Update Article": "Artikel aktualisieren",
      "Add Article": "Artikel hinzufügen",
      services: "Dienstleistungen",
      'Change Email':'E-Mail ändern',
       "Classification": "Klassifikation"
    },
  },
};

const savedLanguage = localStorage.getItem("language") || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
