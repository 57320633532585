import { useTranslation } from "react-i18next";
import './Tabe.css'

export default function Tabe({word,isChecked}){
  const { t } = useTranslation();

    return(
        <h1
        className={`text-center border_tape ${!isChecked? 'border_tape' :'border_tape1' }`}
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        {" "}
        {t(`${word}`)}
      </h1>
    )
}