
// export const baseUrl=`http://127.0.0.1:8000/api`;
// export const Url=`http://127.0.0.1:8000`;

// export const baseUrl=`http://65.21.14.59/api`;
// export const Url=`http://65.21.14.59`;


// export const baseUrl=`https://eyeengineer.com/api`;
// export const Url=`https://eyeengineer.com`;

export const baseUrl=`https://www.eyeengineer.com/api`;
export const Url=`https://www.eyeengineer.com/`;

export const GOOGLE_CALL_BACK="auth/google/callback"

