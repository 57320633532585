import Content from "../../Components/Website/Content/Content";
import Tabe from "../../Components/Tape/Tabe";

export default function Capabilities({isChecked}){
    return(
        <>
         <Tabe word={'OurCapabilities'} isChecked={isChecked}/>
            <Content/>
        </>
    )
}